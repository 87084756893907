import applicationKeys from './src/application';

const components = {
};

const applicationKeysMapper = i18n => applicationKeys(i18n);

const componentsKeysMapper = i18n => {
  const i18nKeys = {};
  Object.keys(components).forEach(name => i18nKeys[name] = components[name](i18n));
  return i18nKeys;
};

export {
  applicationKeysMapper,
  componentsKeysMapper,
};
