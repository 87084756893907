import React from 'react';
import PropTypes from 'prop-types';

const MEBenefit = ({ image, title, text }) => (
  <div className={`me-benefit ${image}`}>
    <div className="me-benefit-image" />
    <div className="me-benefit-content">
      <h4 className="me-benefit-title">{title}</h4>
      <p className="me-benefit-text">{text}</p>
    </div>
  </div>
);

MEBenefit.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    // eslint-disable-next-line consistent-return
    function validate(props, propName, componentName) {
      if (props[propName].type !== 'div') {
        return new Error(
          `Invalid PROP '${propName}' suplied to '${componentName}' Validation Failed.`,
        );
      }
    },
  ]).isRequired,
};

export default MEBenefit;
