import React, { useState } from 'react';
import PropTypes from 'prop-types';

import VisibilitySensor from 'react-visibility-sensor';

const Banner = ({ title, text, image }) => {
  const [changed, setChanged] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = (value) => {
    if (value) {
      setIsVisible(true);
      setChanged(true);
    }
  };

  return (
    <div className={`banner-now ${image}`}>
      <div className="overlay" />
      <div className="main-col">
        <VisibilitySensor
          onChange={handleVisibilityChange}
          partialVisibility
          active={!changed}
        >
          <div
            className={`banner-content transitionable ${
              isVisible && 'visible'
            }`}
          >
            <h1>{title}</h1>
            <p>{text}</p>
          </div>
        </VisibilitySensor>
      </div>
    </div>
  );
};

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string,
};

Banner.defaultProps = {
  image: 'envelope-es',
};

export default Banner;
