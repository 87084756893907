import React, { useState } from 'react';
import PropTypes from 'prop-types';

import VisibilitySensor from 'react-visibility-sensor';

const renderStep = (step, id) => (
  <div className="dispatch-step" key={id}>
    <div className="image">
      <div className="image-svg">
        <div className={step.icon} />
      </div>
    </div>
    <div className="text-container">
      <p className="sub-title">{step.subTitle}</p>
    </div>
  </div>
);

const DispatchSteps = ({ title, steps }) => {
  const [changed, setChanged] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = (value) => {
    if (value) {
      setIsVisible(true);
      setChanged(true);
    }
  };

  return (
    <div className="dispatch-steps-container">
      <VisibilitySensor
        onChange={handleVisibilityChange}
        partialVisibility
        active={!changed}
      >
        <div
          className={`functionality transitionable ${
            isVisible ? 'visible' : ''
          }`}
        >
          <h2>{title}</h2>
          <div className="dispatch-steps">
            {steps.map((step, index) => renderStep(step, index))}
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
};

DispatchSteps.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      subTitle: PropTypes.isRequired,
    }),
  ).isRequired,
};

export default DispatchSteps;
