import React from 'react';
import PropTypes from 'prop-types';

import Integrate from '@components/envios/integrate';
import Banner from '@components/envios/banner';
import Questions from '@components/envios/questions';

import Benefits from '../benefits';

const faqs = [
  {
    question: '¿En qué productos ofreceré Envíos de Mercado Libre?',
    answer: (
      <>
        En todos tus productos que puedan enviarse por correo. Si tenés dudas
        sobre algún producto, podés revisar{' '}
        <a
          href="https://www.mercadolibre.com.ar/ayuda/3163"
          target="_blank"
          rel="noopener noreferrer"
        >
          cuáles son las dimensiones máximas permitidas para enviar paquetes
        </a>
        .
      </>
    ),
  },
  {
    question: '¿Cuándo ofreceré envío gratis?',
    answer: (
      <>
        Ofrecerás envíos gratis si tu producto es nuevo y el precio de venta es
        $ 30.000 o más; o si te compran varios productos y el total de la venta
        supera este monto.{' '}
        <a
          href="https://www.mercadolibre.com.ar/ayuda/3482"
          target="_blank"
          rel="noopener noreferrer"
        >
          Revisar costos y descuentos
        </a>
      </>
    ),
  },
  {
    question: '¿Cuánto pagaré por los envíos?',
    answer: (
      <>
        El costo del envío dependerá del peso y del tamaño del paquete. Tendrás
        descuento según tu reputación.{' '}
        <a
          href="https://www.mercadolibre.com.ar/ayuda/3482"
          target="_blank"
          rel="noopener noreferrer"
        >
          Revisar costos y descuentos
        </a>
      </>
    ),
  },
  {
    question: '¿Cuándo tendré disponible el dinero de mi venta?',
    answer: (
      <>
        Si tenés reputación por vender en Mercado Libre y el producto es nuevo,
        tendrás tu dinero disponible 8 días después de la entrega del producto.
        <br />
        <br />
        En cambio, si no tenés reputación por vender en Mercado Libre o el
        producto es usado, tendrás tu dinero disponible 14 días después de la
        entrega del producto.
        <br />
        <br />
        <a
          href="https://www.mercadolibre.com.ar/reputacion"
          target="_blank"
          rel="noopener noreferrer"
        >
          Consultar mi reputación
        </a>
      </>
    ),
  },
  {
    question: '¿Dónde se despachan los productos?',
    answer: (
      <>
        Podés llevar tus productos a cualquiera de nuestros{' '}
        <a
          href="https://vendedores.mercadolibre.com.ar/nota/como-enviar-tus-ventas-usando-los-puntos-de-despacho"
          target="_blank"
          rel="noopener noreferrer"
        >
          puntos de despacho
        </a>
        .
      </>
    ),
  },
  {
    question: '¿Cuándo tengo que despachar los productos?',
    answer: (
      <>
        Una vez que vendas, prepará el producto y despachalo lo antes que puedas
        para brindar la mejor experiencia a tus clientes y no afectar{' '}
        <a
          href="https://www.mercadolibre.com.ar/ayuda/866"
          target="_blank"
          rel="noopener noreferrer"
        >
          tu reputación por vender en Mercado Libre
        </a>
        . En cada venta te ayudaremos con indicaciones para que despaches a
        tiempo.
        <br />
        <br />
        Si hacés productos personalizados y necesitás tiempo para elaborar tus
        productos, podrás indicar el plazo en tu publicación.{' '}
        <a
          href="https://www.mercadolibre.com.ar/ayuda/3970"
          target="_blank"
          rel="noopener noreferrer"
        >
          Conocé más detalles
        </a>
      </>
    ),
  },
];

const Body = (props) => {
  const { className } = props;

  return (
    <div className={className}>
      <Banner
        title="Enviá tus ventas con Mercado Libre"
        text="Ofrecerás envíos gratis con hasta 50% de descuento y tus productos estarán destacados en todas las búsquedas."
      />
      <Benefits />
      <Questions title="Conocé los detalles" faqs={faqs} />
      <Integrate
        title={
          <>
            Seamos un equipo <br /> Enviá tus ventas con Mercado Libre
          </>
        }
        link="https://www.mercadolibre.com.ar/publicaciones/"
        buttonText="Ir a Publicaciones"
      />
    </div>
  );
};

Body.propTypes = {
  className: PropTypes.string,
};

Body.defaultProps = {
  className: 'body',
};

export default Body;
