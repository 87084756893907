import React from 'react';
import PropTypes from 'prop-types';

import injectI18n from 'nordic/i18n/injectI18n';
import { ErrorScreen } from '@andes/technical-error';

import { checkAndTranslate } from '../../../helpers/scripts/utils';

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(err) {
    this.setState(
      {
        hasError: true,
      },
      () => {
        const { onError } = this.props;

        onError(err);
      },
    );
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      const { i18nKeys } = this.props;

      return (
        <ErrorScreen
          title={checkAndTranslate(i18nKeys, 'GENERIC_ERROR_TITLE')}
          description={checkAndTranslate(i18nKeys, 'GENERIC_ERROR_DESCRIPTION')}
        />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  i18nKeys: PropTypes.object,
  onError: PropTypes.func.isRequired,
};

ErrorBoundary.defaultProps = {
  i18nKeys: null,
};

export default injectI18n(ErrorBoundary);
