import React, { useState } from 'react';
import PropTypes from 'prop-types';

import VisibilitySensor from 'react-visibility-sensor';

const FSBenefit = ({ title, text, image }) => {
  const [changed, setChanged] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = (value) => {
    if (value) {
      setIsVisible(true);
      setChanged(true);
    }
  };

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      offset={{ top: 300 }}
      active={!changed}
    >
      <div
        className={`fs-benefit transitionable${isVisible ? ' visible' : ''}`}
      >
        <div className="foot">
          <h3 className="foot-title">{title}</h3>
          {text.type === 'div' && text}
          {typeof text === 'string' && <p className="foot-text">{text}</p>}
        </div>
        <div className="right">
          <div className={`${image} image`} />
        </div>
      </div>
    </VisibilitySensor>
  );
};

FSBenefit.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    // eslint-disable-next-line consistent-return
    function validate(props, propName, componentName) {
      if (props[propName].type !== 'div') {
        return new Error(
          `Invalid PROP '${propName}' suplied to '${componentName}' Validation Failed.`,
        );
      }
    },
  ]).isRequired,
  image: PropTypes.string.isRequired,
};

export default FSBenefit;
