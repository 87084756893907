import React, { useState } from 'react';
import PropTypes from 'prop-types';

import VisibilitySensor from 'react-visibility-sensor';

import FSBenefit from '../../fs-benefit';

const renderBenefits = (benefitList) =>
  benefitList.map((benefit, index) => {
    const id = index;

    return (
      <FSBenefit
        key={id}
        title={benefit.title}
        text={<div className="foot-text">{benefit.text}</div>}
        image={benefit.image}
      />
    );
  });

const FSBenefits = ({ benefitList }) => {
  const [changed, setChanged] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = (value) => {
    if (value) {
      setIsVisible(true);
      setChanged(true);
    }
  };

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      active={!changed}
    >
      <div
        className={`fs-benefits transitionable${isVisible ? ' visible' : ''}`}
      >
        {renderBenefits(benefitList)}
      </div>
    </VisibilitySensor>
  );
};

FSBenefits.propTypes = {
  benefitList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([
        PropTypes.string,
        // eslint-disable-next-line consistent-return
        function validate(props, propName, componentName) {
          if (props[propName].type !== 'div') {
            return new Error(
              `Invalid PROP '${propName}' suplied to '${componentName}' Validation Failed.`,
            );
          }
        },
      ]).isRequired,
      image: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FSBenefits;
