/* eslint-disable no-nested-ternary */

/**
 * Returns true if it's a valid key.
 *
 * @param {string} key - The wording key.
 */
const isValidKey = (key) =>
  // It is considered a KEY when it is composed of
  // all uppercase letters or all lowercase letters,
  // in addition, on some occasions it can contain numbers and _ to separate the words.
  key && /^([A-Z0-9_]+|[a-z0-9_]+)$/.test(key);

/**
 * Returns the adapted wording.
 *
 * @param {object} map - The object with all the wording keys.
 * @param {string} key - The wording key or plain text.
 * @param {array} params - The array with the wording params.
 */
const checkAndTranslate = (map, key, params) => {
  // In some cases the Middle-end sends the prepared and ready-to-display text instead of the KEY
  const translation = key ? (isValidKey(key) ? map[key] : key) : undefined;

  let translated = translation;

  if (translation && typeof translation === 'function') {
    translated = translation(params);
  }

  return translated;
};

/**
 * Get the free shipping price.
 *
 * @param {object} loyalData - The object with the loyal data.
 * @param {number} loyaltyLevel - The loyalty level.
 */
const getFreeShippingPriceByLoyalty = (loyalData, loyaltyLevel) => {
  let freeShippingPrice = '';

  const loyaltyInfo =
    loyalData && loyalData.levels && loyalData.levels[loyaltyLevel];

  if (loyaltyInfo) {
    const currency = loyaltyInfo.currency_symbol;
    const price =
      loyaltyInfo.price_min &&
      loyaltyInfo.price_min
        .toString()
        .replace(/\d{3}$/, (match) => `.${match}`);

    if (currency && price) {
      freeShippingPrice = `${currency} ${price}`;
    }
  }

  return freeShippingPrice;
};

export { isValidKey, checkAndTranslate, getFreeShippingPriceByLoyalty };
