import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useI18n } from 'nordic/i18n';

const Questions = (props) => {
  const { title, faqs, className, siteId } = props;
  const { i18n } = useI18n();

  const [targetId, setTargetId] = useState('');

  const onClickHandler = (wrapper) => {
    const { target } = wrapper;

    if (!target.id) {
      return;
    }

    if (targetId === target.id) {
      target.checked = false;
      setTargetId('');
    } else {
      setTargetId(target.id);
    }
  };

  const getTags = (faq) => {
    let tags = {};

    if (faq.replace && Object.keys(faq.replace).length > 0) {
      if (Object.keys(faq.replace).includes('all')) {
        tags = { ...faq.replace.all };
      }

      if (Object.keys(faq.replace).includes(siteId)) {
        tags = { ...tags, ...faq.replace[siteId] };
      }
    }

    return tags;
  };

  const renderFaqs = () =>
    faqs
      .filter((faq) =>
        faq.sites && siteId ? faq.sites.includes(siteId) : true,
      )
      .map((faq, index) => {
        const id = `accordion-item-${index}`;

        return (
          <div className="accordion-item tab" key={id}>
            <input
              className="accordion-check"
              id={id}
              type="radio"
              name="tabs"
            />
            <label htmlFor={id}>
              <h4>
                {i18n.gettext(faq.question)}
                <i className="nav-icon-chevron-up" />
              </h4>
            </label>
            <div className="tab-content">
              {typeof faq.answer === 'string' ? (
                <div className="tab-content__answer">
                  {i18n.jsx.gettext(faq.answer, { tags: { ...getTags(faq) } })}
                </div>
              ) : (
                <p className="tab-content__answer">{faq.answer}</p>
              )}
            </div>
          </div>
        );
      });

  return (
    <div className={`faq ${className}`.trim()}>
      <div className="container">
        <h2>{i18n.gettext(title)}</h2>
        <div className="accordion" role="presentation" onClick={onClickHandler}>
          {renderFaqs()}
        </div>
      </div>
    </div>
  );
};

Questions.propTypes = {
  title: PropTypes.string.isRequired,
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.oneOfType([
        PropTypes.string,
        // eslint-disable-next-line consistent-return
        function validate(props, propName, componentName) {
          if (props[propName].type !== 'div') {
            return new Error(
              `Invalid PROP '${propName}' suplied to '${componentName}' Validation Failed.`,
            );
          }
        },
      ]).isRequired,
      site: PropTypes.string,
      links: PropTypes.object,
    }),
  ),
  className: PropTypes.string,
  siteId: PropTypes.string,
};

Questions.defaultProps = {
  faqs: null,
  className: '',
  siteId: null,
};

export default Questions;
