import React, { useState } from 'react';
import PropTypes from 'prop-types';

import VisibilitySensor from 'react-visibility-sensor';

import MEBenefit from '../../me-benefit';

const MEBenefits = ({ title, benefitList }) => {
  const [changed, setChanged] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = (value) => {
    if (value) {
      setIsVisible(true);
      setChanged(true);
    }
  };

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      active={!changed}
    >
      <div className={`me-benefits transitionable ${isVisible && 'visible'}`}>
        <h2 className="me-benefits-title">{title}</h2>
        <div className="me-benefits-content">
          {benefitList.map(({ image, title, text }) => (
            <MEBenefit image={image} title={title} text={text} />
          ))}
        </div>
      </div>
    </VisibilitySensor>
  );
};

MEBenefits.propTypes = {
  title: PropTypes.string.isRequired,
  benefitList: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([
        PropTypes.string,
        // eslint-disable-next-line consistent-return
        function validate(props, propName, componentName) {
          if (props[propName].type !== 'div') {
            return new Error(
              `Invalid PROP '${propName}' suplied to '${componentName}' Validation Failed.`,
            );
          }
        },
      ]).isRequired,
    }).isRequired,
  ),
};

MEBenefits.defaultProps = {
  benefitList: null,
};

export default MEBenefits;
