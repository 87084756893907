import React, { useState } from 'react';
import PropTypes from 'prop-types';

import VisibilitySensor from 'react-visibility-sensor';

const Integrate = ({ title, buttonText, link, image }) => {
  const [changed, setChanged] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = (value) => {
    if (value) {
      setIsVisible(true);
      setChanged(true);
    }
  };

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      active={!changed}
    >
      <div className={`integrate ${image}`}>
        <h2
          className={`integrate-title transitionable ${isVisible && 'visible'}`}
        >
          {title}
        </h2>
        <div className={`btn-cont transitionable ${isVisible && 'visible'}`}>
          <a
            className="btn-white"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {buttonText}
          </a>
        </div>
      </div>
    </VisibilitySensor>
  );
};

Integrate.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.string,
};

Integrate.defaultProps = {
  image: 'box',
};

export default Integrate;
